import axios from 'axios';
import { MutationTree, ActionTree } from 'vuex';
import type { RootState } from '@/store';
import { MutationTypes } from '@/constants';
import {
  Feature,
  VetroResponse,
  isFailedResponse,
  FeatureImageAttributeDomainResponse,
} from '@/types';

export class FeatureState {
  selectedFeature: Feature | null = null;
  shouldDisplayAndHighlightSelectedFeature = false;
  imageAttributeDomain: string | null = null;
  // 'Focus point' refers to either a point on the map at which the user clicks,
  // or the geocoded location of an address search.
  featuresAtFocusPoint: Feature[] = [];
  surveyEnabledSelection = true;
}

export const mutations: MutationTree<FeatureState> = {
  [MutationTypes.SET_SELECTED_FEATURE](state: FeatureState, feature: Feature) {
    state.selectedFeature = feature;
  },
  [MutationTypes.SET_SELECTED_FEATURE_HIGHLIGHT_STATUS](
    state: FeatureState,
    shouldDisplayAndHighlight: boolean,
  ) {
    state.shouldDisplayAndHighlightSelectedFeature = shouldDisplayAndHighlight;
  },
  [MutationTypes.SET_SELECTED_FEATURE_SURVEY_VISIBILITY](
    state: FeatureState,
    surveyEnabledSelection: boolean,
  ) {
    state.surveyEnabledSelection = surveyEnabledSelection;
  },
  [MutationTypes.RESET_FEATURE_STATE](state: FeatureState) {
    Object.assign(state, new FeatureState());
  },
  [MutationTypes.SET_IMAGE_ATTRIBUTE_DOMAIN](state: FeatureState, domain: string) {
    state.imageAttributeDomain = domain;
  },
  [MutationTypes.SET_FEATURES_AT_FOCUS_POINT](state: FeatureState, features: Feature[]) {
    state.featuresAtFocusPoint = features;
  },
};

export const actions: ActionTree<FeatureState, RootState> = {
  setSelectedFeature(
    { commit, dispatch },
    {
      feature,
      shouldDisplayAndHighlight = true,
      surveyEnabledSelection = true,
    }: {
      feature: Feature | null;
      shouldDisplayAndHighlight: boolean;
      surveyEnabledSelection: boolean;
    },
  ) {
    commit(MutationTypes.SET_SELECTED_FEATURE, feature);
    commit(MutationTypes.SET_SELECTED_FEATURE_HIGHLIGHT_STATUS, shouldDisplayAndHighlight);
    commit(MutationTypes.SET_SELECTED_FEATURE_SURVEY_VISIBILITY, surveyEnabledSelection);

    if (feature) dispatch('sidebar/setSurveyActive', true, { root: true });
  },
  resetFeatureState({ commit }) {
    commit(MutationTypes.RESET_FEATURE_STATE);
  },
  async fetchFeatureImageAttributeDomain({ commit, dispatch }) {
    const { data: response }: { data: VetroResponse<FeatureImageAttributeDomainResponse> } =
      await axios.get('/v2/environment/cloud_front_domains');

    if (isFailedResponse(response)) {
      const error = new Error(`Failed to fetch feature attribute image domains.`);
      dispatch('error/setCriticalError', error, { root: true });
      throw error;
    }

    commit(MutationTypes.SET_IMAGE_ATTRIBUTE_DOMAIN, response.result.images);
  },
  setFeaturesAtFocusPoint({ commit }, features: Feature[]) {
    commit(MutationTypes.SET_FEATURES_AT_FOCUS_POINT, features);
  },
};

export default {
  namespaced: true,
  state: (): FeatureState => new FeatureState(),
  mutations,
  actions,
};
