















import Vue from 'vue';
import VueMeta from 'vue-meta';
import { mapActions, mapGetters, mapState } from 'vuex';
import SharedHeader from '@/components/SharedHeader.vue';
import CriticalError from '@/components/CriticalError.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ModalsContainer from '@/components/modals/ModalsContainer.vue';
import { LAYERS_SHOWN_ON_MAP_URL_LOCATION, setUrlParam } from './util/urlAttributesParser';

Vue.use(VueMeta);

export default Vue.extend({
  name: 'app',
  components: {
    SharedHeader,
    CriticalError,
    LoadingSpinner,
    ModalsContainer,
  },
  data() {
    return { initializationError: null };
  },
  async mounted() {
    let initialRouteName = this.viewAttributes?.initialRouteName;
    try {
      if (!this.configHasLoaded) {
        initialRouteName = await this.initializeViewConfig().then(
          (rawConfig) => rawConfig.attributes.initialRouteName,
        );
      }

      if (!initialRouteName) {
        throw new Error('No initial route was available!');
      }

      // Kick off layer retrieval now that we have our config
      this.getLayersAndCategories();

      // Allows us to display images in the feature info panel
      this.fetchFeatureImageAttributeDomain();

      if (this.$router.currentRoute.name === 'root') {
        this.$router.push({
          name: initialRouteName,
          params: this.$router.currentRoute.params,
          query: this.$router.currentRoute.query, // Preserve any query parameters, like analytics metadata
        });
      }
    } catch (error) {
      this.setCriticalError(error);
    }
  },
  watch: {
    layerStatusMap(newLayerStatusMap): void {
      if (this.viewAttributes.keepUrlUpdated) {
        const displayedLayerIds = Object.keys(newLayerStatusMap).filter(
          (layerId) => newLayerStatusMap[layerId],
        );
        setUrlParam(LAYERS_SHOWN_ON_MAP_URL_LOCATION, displayedLayerIds.join(','));
      }
    },
  },
  computed: {
    ...mapState('layers', ['layerStatusMap']),
    ...mapState('config', ['viewAttributes', 'layerIds']),
    ...mapGetters('config', ['configHasLoaded']),
    ...mapState('error', ['criticalError']),
  },
  methods: {
    ...mapActions('config', ['initializeViewConfig']),
    ...mapActions('layers', ['getLayersAndCategories']),
    ...mapActions('features', ['fetchFeatureImageAttributeDomain']),
    ...mapActions('error', ['setCriticalError']),
  },
  metaInfo() {
    const { organizationTitle, viewTitle } = this.viewAttributes || {};

    if (viewTitle) {
      return {
        title: `${viewTitle} - ${organizationTitle}`,
      };
    }

    return { title: organizationTitle };
  },
});
