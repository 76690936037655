import _mapValues from 'lodash.mapvalues';
import camelcaseKeys from 'camelcase-keys';
import { hasThresholdStyling } from 'vetro-mapbox';
import { Layer, LayerStyle, LayerSymbol, CategorizedSymbolWithLayerInfo } from '@/types/layers';

/**
 * Convert layer symbol properties to camelCase for mapbox.
 * This isn't handled by our axios interceptor, because the symbol
 * key must not have its case changed. Eventually, we may want to
 * do this in a response pipeline a la fibermap.
 */
export const formatLayers = (layers: Layer[]): Layer[] =>
  layers.map((layer) => {
    const symbols = _mapValues(layer.style.symbols, (symbol: LayerSymbol) => camelcaseKeys(symbol));

    const style = { ...layer.style, symbols };

    return { ...layer, style };
  });

/**
 * Attempt to apply a theme to a list of layers
 *
 * Will always return the full list of layers passed to it,
 * though if it fails to apply the theme the layers will be
 * returned without any changes applied.
 */
export const tryApplyThemeToLayers = async (
  layers: Layer[],
  layerStyles: LayerStyle[],
): Promise<Layer[]> => {
  try {
    const restyledLayers = layers.map((layer) => {
      const layerStyle = layerStyles.find((s) => s.layerId === layer.id);
      return {
        ...layer,
        style: layerStyle ?? layer.style,
      };
    });
    return restyledLayers;
  } catch (error) {
    console.error(`Failed to apply map theme`); // eslint-disable-line no-console
    console.error(error); // eslint-disable-line no-console
    return layers;
  }
};

export const getOrderedCategorizedSymbols = (layer: Layer): CategorizedSymbolWithLayerInfo[] => {
  const { style, id, geomType } = layer;

  if (!style.categorizedAttributeLabel || !style.symbols) return [];

  const categorizedAttribute = layer.availableAttributes[style.categorizedAttributeLabel];

  const { permittedValues, permittedValueDescriptions } = categorizedAttribute;

  const orderedSymbols = Object.entries(style.symbols)
    .map(([attributeValue, symbol]) => ({
      attributeValue,
      description: permittedValueDescriptions && permittedValueDescriptions[attributeValue],
      symbol,
      geomType,
      layerId: id,
    }))
    .sort((a, b) => {
      if (a.attributeValue === 'Other') return 1;
      if (b.attributeValue === 'Other') return -1;

      if (a.symbol.displayOrder && b.symbol.displayOrder) {
        return a.symbol.displayOrder - b.symbol.displayOrder;
      }

      if (!permittedValues) {
        if (hasThresholdStyling(layer)) return Number(a.attributeValue) - Number(b.attributeValue);
        if (!layer.rasterId) return 0;
        return a.attributeValue.localeCompare(b.attributeValue);
      }

      const l = permittedValues.length;
      const aIndex = permittedValues.findIndex((pv) => pv === a.attributeValue);
      const bIndex = permittedValues.findIndex((pv) => pv === b.attributeValue);

      const aPriority = aIndex === -1 ? l : aIndex;
      const bPriority = bIndex === -1 ? l : bIndex;

      return aPriority - bPriority;
    });

  return orderedSymbols;
};
