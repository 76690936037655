import axios from 'axios';
import _groupBy from 'lodash.groupby';
import _sortBy from 'lodash.sortby';
import _keyBy from 'lodash.keyby';
import _cloneDeep from 'lodash.clonedeep';
import _merge from 'lodash.merge';
import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { vetroLayerIdToMapboxLayerId } from 'vetro-mapbox';
import type { RootState } from '@/store';
import { MAX_CATEGORIES, MutationTypes } from '@/constants';
import { Layer, Category, CategorizedAttributeStatusItem } from '@/types/layers';
import { formatLayers, tryApplyThemeToLayers } from '@/util/layers';
import { GeomType } from '@/types';

const layerToCategoricAttributeStatusMap = (layer: Layer): Record<string, boolean> => {
  return Object.keys(layer.style.symbols ?? {}).reduce(
    (attributeValueObject: Record<string, boolean>, value: string) => {
      return { ...attributeValueObject, [value]: false };
    },
    {},
  );
};

export class LayerState {
  layers: Layer[] = [];
  categories: Category[] = [];
  // Stores the relationship of a layer's ID to its display status on the map (visible/not visible)
  layerStatusMap: Record<number, boolean> = {};
  categorizedAttributeStatusMap: Record<number, Record<string, boolean>> = {};
}

export const mutations: MutationTree<LayerState> = {
  [MutationTypes.SET_CATEGORIES](state: LayerState, categories: Category[]) {
    state.categories = categories;
  },
  [MutationTypes.SET_LAYERS](state: LayerState, layers: Layer[]) {
    state.layers = layers;
  },
  [MutationTypes.SET_CATEGORIZED_ATTRIBUTE](state: LayerState, layer: Layer) {
    if (layer.style.categorizedAttributeLabel && !layer.style.hideCategorizedAttributes) {
      const categorizedAttributeValues = Object.keys(layer.style.symbols ?? {});
      if (
        categorizedAttributeValues.length > 0 &&
        categorizedAttributeValues.length <= MAX_CATEGORIES
      )
        state.categorizedAttributeStatusMap[layer.id] = categorizedAttributeValues.reduce(
          (acc: Record<string, boolean>, attributeValue: string) => {
            acc[attributeValue] = false;
            return acc;
          },
          {},
        );
    }
  },
  [MutationTypes.TOGGLE_LAYERS](
    state: LayerState,
    { layerIds, status }: { layerIds: number[]; status: boolean },
  ) {
    const newStatuses = Object.fromEntries(layerIds.map((id) => [id, status]));
    state.layerStatusMap = _merge(_cloneDeep(state.layerStatusMap), newStatuses);
  },
  [MutationTypes.TOGGLE_CATEGORIZED_ATTRIBUTES](
    state: LayerState,
    {
      layerAttributes,
      categorizedAttributeValues,
    }: {
      layerAttributes: CategorizedAttributeStatusItem[];
      categorizedAttributeValues: Record<string, boolean>;
    },
  ) {
    const newCategorizedAttributeStatuses = layerAttributes.reduce(
      (
        newStatuses: Record<number, Record<string, boolean>>,
        { layerId, attributeValue, attributeStatus }: CategorizedAttributeStatusItem,
      ) => {
        if (!newStatuses[layerId]) {
          let initialState = {};
          if (state.categorizedAttributeStatusMap[layerId]) {
            initialState = _cloneDeep(state.categorizedAttributeStatusMap[layerId]);
          } else {
            initialState = categorizedAttributeValues;
          }
          newStatuses[layerId] = initialState;
        }
        newStatuses[layerId][attributeValue] = attributeStatus;
        return newStatuses;
      },
      {},
    );
    state.categorizedAttributeStatusMap = _merge(
      _cloneDeep(state.categorizedAttributeStatusMap),
      newCategorizedAttributeStatuses,
    );
  },
};

export const getters: GetterTree<LayerState, RootState> = {
  layerById(state) {
    const layersById = _keyBy(state.layers, (layer: Layer) => layer.id);
    return (id: number) => layersById[id];
  },
  allLayersActiveForCategory(state, localGetters) {
    return (categoryId: number) =>
      localGetters.layersByCategoryId[categoryId].layers.every(
        (layer: Layer) => state.layerStatusMap[layer.id],
      );
  },
  attributeStatusMapByLayerId(state) {
    return (layerId: number) => state.categorizedAttributeStatusMap[layerId] ?? {};
  },
  layersByCategoryId(state) {
    const groupedLayers = _groupBy(state.layers, 'categoryId');
    return state.categories
      .filter((category) => !category.isSource)
      .reduce((accum: Record<string, unknown>, c) => {
        accum[c.id] = {
          category: c,
          layers: groupedLayers[c.id] ? _sortBy(groupedLayers[c.id], 'sortOrder') : [],
        };
        return accum;
      }, {});
  },
  sortedCategoriesWithLayers(__state, localGetters) {
    const categoriesWithLayers: {
      category: Category;
      layers: Layer[];
    }[] = Object.values(localGetters.layersByCategoryId);

    return _sortBy(categoriesWithLayers, 'category.sortOrder');
  },
  sortedLayers(__state, localGetters) {
    return localGetters.sortedCategoriesWithLayers.flatMap(
      ({ layers }: { layers: Layer[] }) => layers,
    );
  },
  activeLayerIds(state) {
    return state.layers.map((layer: Layer) => layer.id).filter((id) => state.layerStatusMap[id]);
  },
  layerIdAttrMap(state) {
    return (attrName: keyof Layer) =>
      state.layers.reduce((accum: Record<string, unknown>, layer) => {
        accum[layer.id] = layer[attrName];
        return accum;
      }, {});
  },
  layerIdGeomTypeMap(__state, localGetters) {
    return localGetters.layerIdAttrMap('geomType') as Record<number, GeomType>;
  },
  layerIdFeatureTableMap(__state, localGetters) {
    return localGetters.layerIdAttrMap('featureTable');
  },
  layerIdLabelMap(__state, localGetters) {
    return localGetters.layerIdAttrMap('label');
  },
  /*
   * {
   *   '9-point': 9,
   *   '14-linestring': 14,
   *   etc........
   * }
   */
  mapboxToVetroLayerIdMap(state, localGetters) {
    return state.layers.reduce((accum: Record<string, number>, layer) => {
      const mapboxLayerId = vetroLayerIdToMapboxLayerId(
        layer.id,
        localGetters.layerIdFeatureTableMap,
      );
      if (mapboxLayerId === null) return accum;

      accum[mapboxLayerId] = layer.id;

      return accum;
    }, {});
  },
};

export const actions: ActionTree<LayerState, RootState> = {
  async getLayersAndCategories({ state, commit, dispatch, rootState }) {
    try {
      const { data } = await axios.get('/v2/layers');
      const { layers, categories } = data.result;

      const { layerIds: viewableLayerIds, initialMapTheme, viewAttributes } = rootState.config;
      const { layersShownOnMapLoad } = viewAttributes ?? {};

      let viewableLayers: Layer[] = viewableLayerIds
        ? layers.filter(({ id }: Layer) => viewableLayerIds.includes(id))
        : [];

      if (initialMapTheme) {
        viewableLayers = await tryApplyThemeToLayers(viewableLayers, initialMapTheme);
      }

      commit(MutationTypes.SET_LAYERS, formatLayers(viewableLayers));
      state.layers.forEach((layer) => {
        commit(MutationTypes.SET_CATEGORIZED_ATTRIBUTE, layer);
      });
      const layerIdsThatAreOn = layersShownOnMapLoad ?? viewableLayerIds;

      const allViewableLayerIds = viewableLayerIds ?? [];
      allViewableLayerIds.forEach(async (layerId) =>
        dispatch('setLayerStatus', { layerId, status: false }),
      );
      const allLayersThatAreOn = layerIdsThatAreOn ?? [];
      allLayersThatAreOn.forEach(async (layerId) =>
        dispatch('setLayerStatus', { layerId, status: true }),
      );

      commit(MutationTypes.SET_CATEGORIES, categories);
    } catch (error) {
      dispatch('alert/failureAlert', 'Layers failed to load.', {
        root: true,
      });
    }
  },
  displayLayers({ rootState, dispatch }, layerIds: number[]) {
    const { layerIds: viewableLayerIds } = rootState.config;
    const allViewableLayerIds = viewableLayerIds ?? [];
    allViewableLayerIds.forEach(async (layerId) =>
      dispatch('setLayerStatus', { layerId, status: false }),
    );
    layerIds.forEach(async (layerId) => dispatch('setLayerStatus', { layerId, status: true }));
  },
  toggleAllCategorizedAttributesForLayer(
    { commit, getters: localGetters },
    { layerId, status }: { layerId: number; status: boolean },
  ) {
    const layer = localGetters.layerById(layerId);
    const categorizedAttributeValues = layerToCategoricAttributeStatusMap(layer);

    const layerAttributes = Object.keys(categorizedAttributeValues).map((attributeValue) => ({
      layerId,
      attributeValue,
      attributeStatus: status,
    }));

    commit(MutationTypes.TOGGLE_CATEGORIZED_ATTRIBUTES, {
      layerAttributes,
      categorizedAttributeValues,
    });
  },
  async setLayerStatus(
    { state, commit, dispatch },
    { layerId, status }: { layerId: number; status: boolean },
  ) {
    let layerStatus = status;
    const currentCategorizedAttributeStatusValues = Object.values(
      state.categorizedAttributeStatusMap[layerId] ?? {},
    );

    // The logic below checks to see if the layer checkbox for the current
    // layer id is indeterminate. In that case the default status above will
    // be false, but when clicking an indeterminite checkbox the state
    // changes to checked, so we want status to be true.
    if (
      currentCategorizedAttributeStatusValues.length > 0 &&
      currentCategorizedAttributeStatusValues.some((statusCheckTrue) => statusCheckTrue) &&
      currentCategorizedAttributeStatusValues.some((statusCheckFalse) => !statusCheckFalse)
    ) {
      layerStatus = true;
    }
    commit(MutationTypes.TOGGLE_LAYERS, { layerIds: [layerId], status: layerStatus });

    await dispatch('toggleAllCategorizedAttributesForLayer', { layerId, status: layerStatus });
  },
  async toggleLayer({ state, rootState, dispatch }, layerId: number) {
    const status = !state.layerStatusMap[layerId];
    await dispatch('setLayerStatus', { layerId, status });
    if (rootState.features.selectedFeature?.xVetro.layerId === layerId) {
      dispatch('features/setSelectedFeature', { feature: null }, { root: true });
    }
  },
  toggleCategory({ dispatch, getters: localGetters }, categoryId: number) {
    const status = !localGetters.allLayersActiveForCategory(categoryId);

    const layerIds = localGetters.layersByCategoryId[categoryId].layers.map(
      (layer: Layer) => layer.id,
    );

    layerIds.forEach(async (layerId: number) => {
      await dispatch('setLayerStatus', { layerId, status });
    });
  },
  toggleCategorizedAttribute(
    { commit, state, getters: localGetters },
    { layerId, attributeValue }: { layerId: number; attributeValue: string },
  ) {
    const attributeStatus = !(
      state.categorizedAttributeStatusMap[layerId] &&
      state.categorizedAttributeStatusMap[layerId][attributeValue]
    );
    const layer = localGetters.layerById(layerId);
    const categorizedAttributeValues = layerToCategoricAttributeStatusMap(layer);
    commit(MutationTypes.TOGGLE_CATEGORIZED_ATTRIBUTES, {
      layerAttributes: [{ layerId, attributeValue, attributeStatus }],
      categorizedAttributeValues,
    });
    const updatedCategorizedAttributeValues = Object.values(
      state.categorizedAttributeStatusMap[layerId],
    );
    const layerStatus = state.layerStatusMap[layerId];
    // The logic below is so we can handle the layer state when at least 1 categorized
    // attribute is active, or all categorized attributes are inactive
    if (!layerStatus && updatedCategorizedAttributeValues.some((value) => value)) {
      commit(MutationTypes.TOGGLE_LAYERS, { layerIds: [layerId], status: true });
    } else if (layerStatus && updatedCategorizedAttributeValues.every((value) => !value)) {
      commit(MutationTypes.TOGGLE_LAYERS, { layerIds: [layerId], status: false });
    }
  },
};

export default {
  namespaced: true,
  state: (): LayerState => new LayerState(),
  mutations,
  getters,
  actions,
};
