import axios from 'axios';
import { Polygon, Position, Point, AllGeoJSON } from '@turf/helpers';
import snakecaseKeys from 'snakecase-keys';
import _omitBy from 'lodash.omitby';
import _isUndefined from 'lodash.isundefined';
import _cloneDeep from 'lodash.clonedeep';
import _uniq from 'lodash.uniq';
import _chunk from 'lodash.chunk';
import { getLatLonPolygonFromPixel, hasThresholdStyling } from 'vetro-mapbox';
import {
  Feature,
  VetroResponse,
  isFailedResponse,
  NullableGeometry,
  SubmissionConfig,
  FeatureUpsertResult,
  ContentBlockConfig,
  PolygonIntersectionMapping,
  RedirectionConfig,
  Layer,
  LayerSymbol,
} from '@/types';

export const pointInPolygonSearch = async (
  coordinates: Position,
  polygonPlanIds: number[],
  polygonLayerId: number,
): Promise<Feature<Polygon>[]> => {
  const payload = {
    position: coordinates,
    polygon_layer_ids: [polygonLayerId],
    plan_ids: polygonPlanIds,
  };
  const { data: response }: { data: VetroResponse<Feature<Polygon>[]> } = await axios.post(
    '/v2/features/polygon/intersection/position',
    payload,
  );

  if (isFailedResponse(response)) {
    throw new Error(
      `Failed to find polygon intersection for coordinates [${coordinates[0]}, ${coordinates[1]}]: ${response.message}'`,
    );
  }

  return response.result;
};

/**
 * Given a mapping and a list of polygons that might match it, return the content
 * if any polygon(s) match, or undefined if not
 */
export const anyFeatureMatchesProperty = (
  propertyName: string,
  value: string | number,
  polygons: Feature<NullableGeometry>[],
): boolean => {
  return polygons.some((polygon) => polygon.properties[propertyName] === value);
};

export const mapPolygonsToContent = (
  polygons: Feature<NullableGeometry>[],
  config: PolygonIntersectionMapping,
): (string | ContentBlockConfig)[] => {
  const { propertyName, contentMap } = config;
  // Find any content mappings which match our intersecting polygons, and extract their content
  //  in the order that they were configured
  const mappedContent = contentMap
    .filter((mapping) => anyFeatureMatchesProperty(propertyName, mapping.value, polygons))
    .map((cm) => cm.content);
  return mappedContent;
};

export const mapPolygonsToRedirectURL = (
  polygons: Feature<NullableGeometry>[],
  redirectConfig: RedirectionConfig,
): string | null => {
  const redirectPolygonIntersectionMapping = redirectConfig.polygonIntersectionMapping;
  if (!redirectPolygonIntersectionMapping) {
    return null;
  }
  const mappedContent = mapPolygonsToContent(polygons, redirectPolygonIntersectionMapping);
  const [content] = mappedContent;
  return <string>content ?? null;
};

export const buildFeature = ({
  layerId,
  planId,
  geometry,
  properties,
  parentVetroId,
  child,
}: {
  layerId: number;
  planId: number;
  geometry?: NullableGeometry;
  properties: Record<string, string | number>;
  parentVetroId?: string;
  child?: Feature;
}): Feature<NullableGeometry> => {
  const feature: Feature = {
    xVetro: { layerId, planId, ..._omitBy({ parentVetroId, child }, _isUndefined) },
    properties,
  };

  /**
   * - When creating a child feature alongside a new parent, the child must NOT
   *   have any geometry property.
   *
   * - When creating a child feature for an existing parent, the child's
   *   geometry must be NULL.
   *
   * Hence _isUndefined here, rather than _isNil.
   */
  if (!_isUndefined(geometry)) feature.geometry = geometry;

  return feature;
};

export const formatFeatureForSubmission = (feature: Feature): Record<string, unknown> => {
  const { xVetro, properties, ...others } = feature;

  // if a feature has a child, we must format that as well
  const xv = xVetro?.child
    ? {
        ...snakecaseKeys(xVetro as Record<string, unknown>),
        child: formatFeatureForSubmission(xVetro.child),
      }
    : snakecaseKeys(xVetro as Record<string, unknown>);

  return { 'x-vetro': xv, properties, ...others };
};

export const createChildFeature = async ({
  properties,
  parent,
  submissionConfig,
  geometry = null,
}: {
  properties: Record<string, string | number>;
  parent: Feature | null;
  submissionConfig: SubmissionConfig;
  geometry: Point | null;
}): Promise<Feature> => {
  const { childLayerId: layerId, submissionPlanId: planId } = submissionConfig;

  const feature = buildFeature({
    layerId,
    planId,
    parentVetroId: parent?.xVetro.vetroId,
    geometry,
    properties,
  });

  const { data: response }: { data: VetroResponse<Feature[]> } = await axios.post('/v2/features', {
    features: [formatFeatureForSubmission(feature)],
  });

  if (isFailedResponse(response)) {
    throw new Error('Failed to submit survey.');
  }

  const [child] = response.result;

  return child;
};

export const upsertParentWithChild = async ({
  properties,
  parent,
  submissionConfig,
  addressAttribute,
}: {
  properties: Record<string, string | number>;
  parent: Feature;
  submissionConfig: SubmissionConfig;
  addressAttribute: string;
}): Promise<[Feature, Feature]> => {
  const { submissionPlanId: planId, parentLayerId, childLayerId } = submissionConfig;

  const child = buildFeature({
    layerId: childLayerId,
    planId,
    properties,
  });

  const parentFeature = _cloneDeep(parent);

  parentFeature.xVetro = {
    ...parentFeature.xVetro,
    child,
  };

  const { data: response }: { data: VetroResponse<FeatureUpsertResult[]> } = await axios.put(
    '/v2/features/ensure_unique',
    {
      features: [formatFeatureForSubmission(parentFeature)],
      unique_attributes: [{ layer_id: parentLayerId, attributes: [addressAttribute] }],
      return_children: true,
    },
  );

  if (isFailedResponse(response)) {
    throw new Error('Failed to upsert Service Location with Customer Record.');
  }

  const [serviceLocation, customer] = response.result[0].vetroFeatures;

  return [serviceLocation, customer];
};

export const getIntersectingFeatures = async ({
  geometry,
  layerIds,
  planIds,
}: {
  geometry: AllGeoJSON;
  layerIds: number[];
  planIds: number[];
}): Promise<Feature[]> => {
  const { data: response }: { data: VetroResponse<Feature[]> } = await axios.post(
    '/v2/features/intersection',
    {
      geometry,
      layer_ids: layerIds,
      plan_ids: planIds,
      excluded_attributes: [],
    },
  );

  if (isFailedResponse(response)) {
    throw new Error('Failed to fetch features at this location.');
  }

  return response.result;
};

export const getFeaturesWithinPointRadius = async ({
  point,
  layerIds,
  planIds,
}: {
  point: Position;
  layerIds: number[];
  planIds: number[];
}): Promise<Feature[]> => {
  const [x, y] = point;

  const polygon: Polygon = getLatLonPolygonFromPixel({
    point: { x, y },
    map: window.map,
  });

  return getIntersectingFeatures({
    geometry: polygon,
    layerIds,
    planIds,
  });
};

export const getFeatureSymbol = (feature: Feature, layer: Layer): LayerSymbol | undefined => {
  let featureSymbol;
  if (layer) {
    const {
      style: { symbols, categorizedAttributeLabel },
    } = layer;

    if (symbols === undefined) return undefined;

    if (!categorizedAttributeLabel) return symbols.default;

    if (hasThresholdStyling(layer)) {
      const attributeValue = feature.properties[categorizedAttributeLabel];

      const thresholdValues = Object.keys(symbols).slice(0, -1).map(Number).sort();

      if (!attributeValue) return symbols[thresholdValues[0]];

      const threshold = thresholdValues.find((k) => (attributeValue as number) < k);

      return symbols[threshold ?? 'Other'];
    }

    const categorizedAttributeValue = feature.properties[categorizedAttributeLabel] ?? 'Other';

    featureSymbol = symbols[categorizedAttributeValue];
  }
  return featureSymbol;
};

/**
 * Given a feature and a layer, get a default mapping that can be passed to VetroIcon
 * or other utilities expecting a map of value to Vetro symbol definition, with the
 * 'default' value populated, or empty if no symbol can be found.
 */
export const getFeatureDefaultSymbolMap = (
  feature: Feature,
  layer: Layer,
): Record<string, LayerSymbol> => {
  const symbol = getFeatureSymbol(feature, layer);
  return symbol ? { default: symbol } : {};
};

const STANDARD_VETRO_ID_CHUNK_QUANTITY = 100;

/**
 * @typedef {Object} APIResponse
 * @property {boolean} success
 * @property {any} result
 */

/**
 * @typedef {Object} excludedAttribute
 * @property {integer} layerId
 * @property {string} attributeName
 * @property {any[]} excludedValues
 */

/**
 * Fetch data about features
 * @param {string[]} vetroIds
 * @returns {Promise<APIResponse>}
 */
const fetchFeaturesCore = async (
  vetroIds: string[],
  filterForbidden = false,
): Promise<{ success: boolean; result: Feature[] }> => {
  if (vetroIds.length === 0) {
    return { success: true, result: [] };
  }
  const urlBase = `/v2/features/${_uniq(vetroIds)}`;
  const url = filterForbidden ? `${urlBase}?filter_forbidden=${filterForbidden}` : urlBase;
  const { data } = await axios.get(url);
  return data;
};

/**
 * Fetch data about features in batches (to prevent 414 error)
 * @param {object[]} vetroIds
 * @returns {Promise<Feature>}
 */
export const fetchFeatures = async (
  vetroIds: string[],
  filterForbidden = false,
): Promise<{ result: Feature[] }> => {
  // The vetroIds are being chunked twice. The inner chunking is related to the url character limit.
  // The outer chunking is related to the maximum number of concurrent requests. Both are browser limitations.
  const outerChunks = _chunk(
    _chunk(_uniq(vetroIds), STANDARD_VETRO_ID_CHUNK_QUANTITY),
    STANDARD_VETRO_ID_CHUNK_QUANTITY,
  );
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const outerChunk of outerChunks) {
    // eslint-disable-next-line no-await-in-loop
    const dataChunk = await Promise.all(
      outerChunk.map((chunk) => fetchFeaturesCore(chunk, filterForbidden)),
    );
    data.push(dataChunk);
  }

  const features = data
    .flat()
    .map((d) => d.result)
    .flat();

  return { result: features };
};
