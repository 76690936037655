import axios from 'axios';
import { MutationTree, ActionTree } from 'vuex';
import type { RootState } from '@/store';
import { MutationTypes, SIDEBAR_SPINNER, SELECTION_TYPE, MAP_ACTION_TYPE } from '@/constants';
import {
  VetroResponse,
  AddressSearchResult,
  AddressPrediction,
  isFailedResponse,
  GeocodeResult,
  NullableGeometry,
} from '@/types';
import { Position } from '@turf/helpers';
import { buildFeature, getIntersectingFeatures } from '@/util/features';
import { pointFromPosition } from '@/util/geom';
import booleanIntersects from '@turf/boolean-intersects';

export class SearchState {
  selectedAddress: AddressPrediction | null = null;
  // A 'null' addressPredictions means we have not asked for predicitons,
  //  an empty list means that we asked the API and received no predictions
  addressPredictions: AddressPrediction[] | null = null;
}

export const mutations: MutationTree<SearchState> = {
  [MutationTypes.SET_SELECTED_ADDRESS](state: SearchState, address: AddressPrediction) {
    state.selectedAddress = address;
  },
  [MutationTypes.SET_ADDRESS_PREDICTIONS](state: SearchState, results: AddressPrediction[]) {
    state.addressPredictions = results;
  },
  [MutationTypes.RESET_SEARCH_STATE](state: SearchState): void {
    Object.assign(state, new SearchState());
  },
};

export const actions: ActionTree<SearchState, RootState> = {
  async fetchAddressPredictions({ commit, dispatch }, address: string) {
    if (!address) {
      dispatch('resetAddressPredictions');
      return;
    }

    /**
     * Our address search response items will either be:
     * - Feature objects (if sourced internally from a service location layer)
     * - Strings (if sourced externally, i.e., from google)
     */
    const { data: response }: { data: VetroResponse<AddressSearchResult[]> } = await axios.get(
      `/v2/address/search/${address}`,
    );
    if (isFailedResponse(response)) {
      const error = new Error(
        `Failed to retrieve search results for address '${address}: ${response.message}'`,
      );
      dispatch('alert/failureAlert', 'Failed to search by address, please refresh and try again', {
        root: true,
      });
      throw error;
    }

    const predictions = response.result.map(({ data }) => data);

    commit(MutationTypes.SET_ADDRESS_PREDICTIONS, predictions);
  },
  async setSelectedAddress(
    { commit, dispatch, rootState, rootGetters },
    address: AddressPrediction,
  ) {
    commit(MutationTypes.SET_SELECTED_ADDRESS, address);

    dispatch('spinners/activateSpinner', SIDEBAR_SPINNER, {
      root: true,
    });

    const isAddressSearchMode =
      rootState.config.viewAttributes?.selectionType === SELECTION_TYPE.ADDRESS_SEARCH;

    let { feature } = address;

    if (!feature) {
      const { data: response }: { data: VetroResponse<GeocodeResult> } = await axios.get(
        `/v2/address/geocode/${address.address}`,
      );

      if (isFailedResponse(response)) {
        const error = new Error(`Failed to geocode address '${address}'`);
        dispatch('error/setCriticalError', error, { root: true });
        throw error;
      }

      const { geometry, properties } = response.result;

      const submissionConfig = rootState.config.viewAttributes?.submissionConfig;

      // If submissionConfig has not been configured, it does not matter
      // what the built feature's layerId or planId is. Since we won't
      // be submitting the feature.
      const layerId = submissionConfig ? submissionConfig.parentLayerId : 0;
      const planId = submissionConfig ? submissionConfig.submissionPlanId : 0;

      feature = buildFeature({
        geometry: geometry as NullableGeometry,
        properties,
        layerId,
        planId,
      });
    }

    const coordinates = feature.geometry?.coordinates;

    if (rootState.config.viewAttributes?.selectionBounds) {
      const isInBounds = booleanIntersects(
        rootState.config.viewAttributes.selectionBounds,
        pointFromPosition(coordinates as number[]),
      );
      if (!isInBounds) {
        dispatch('spinners/deactivateSpinner', SIDEBAR_SPINNER, {
          root: true,
        });
        dispatch(
          'map/triggerMapAction',
          { type: MAP_ACTION_TYPE.JUMP, payload: { coordinates } },
          { root: true },
        );
        dispatch('alert/failureAlert', 'Address is outside of bounds', {
          root: true,
        });
        return;
      }
    }

    dispatch('map/setActiveLocation', { coordinates }, { root: true });

    if (isAddressSearchMode) {
      dispatch(
        'features/setSelectedFeature',
        { feature, shouldDisplayAndHighlight: false },
        {
          root: true,
        },
      );
    } else if (rootGetters['config/parentlessCommentsEnabled']) {
      const intersectingFeatures = await getIntersectingFeatures({
        geometry: { type: 'Point', coordinates: coordinates as Position },
        layerIds: rootGetters['layers/activeLayerIds'],
        planIds: rootState.config.planIds,
      });

      dispatch('features/setFeaturesAtFocusPoint', intersectingFeatures, { root: true });

      dispatch(
        'features/setSelectedFeature',
        { feature: null, shouldDisplayAndHighlight: false },
        {
          root: true,
        },
      );

      dispatch('sidebar/setSurveyActive', true, { root: true });
    }

    await dispatch(
      'polygonIntersections/setPolygonIntersectionRelationships',
      { coordinates },
      {
        root: true,
      },
    );
    dispatch('spinners/deactivateSpinner', SIDEBAR_SPINNER, {
      root: true,
    });
  },
  resetSelectedAddress({ commit, dispatch, rootState }) {
    if (
      rootState.features.selectedFeature?.geometry?.coordinates === rootState.map.activeLocation
    ) {
      // Deselect address if it was the feature selected
      dispatch(
        'features/setSelectedFeature',
        { feature: null, shouldDisplayAndHighlight: false },
        {
          root: true,
        },
      );
      dispatch('polygonIntersections/resetPolygonIntersections', null, {
        root: true,
      });
    }
    commit(MutationTypes.SET_SELECTED_ADDRESS, null);
    dispatch('map/resetActiveLocation', null, { root: true });
  },
  resetAddressPredictions({ commit }) {
    commit(MutationTypes.SET_ADDRESS_PREDICTIONS, null);
  },
  resetSearchState({ commit }) {
    commit(MutationTypes.RESET_SEARCH_STATE);
  },
};

export default {
  namespaced: true,
  state: (): SearchState => new SearchState(),
  mutations,
  actions,
};
