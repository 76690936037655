import { PolygonIntersectionMapping } from './config';

export enum ContentBlockTypes {
  RichText = 'richText',
  SpeedTest = 'speedTest',
  SingleLineTextInput = 'singleLineTextInput',
  MultiLineTextInput = 'multiLineTextInput',
  RadioButtonInput = 'radioButtonInput',
  CustomerAddressInput = 'customerAddressInput',
  DropdownInput = 'dropdownInput',
  CheckboxInput = 'checkboxInput',
  CheckboxGroupInput = 'checkboxGroupInput',
  DividerBlock = 'divider',
  EmailInput = 'emailInput',
  FeatureInfo = 'featureInfo',
  ParentSelectionInput = 'parentSelectionInput',
}

// The current sole purpose of this array is to determine if we need a submit button. There might be a better way?
export const CONTENT_BLOCK_INPUT_TYPES = [
  ContentBlockTypes.SingleLineTextInput,
  ContentBlockTypes.MultiLineTextInput,
  ContentBlockTypes.RadioButtonInput,
  ContentBlockTypes.CustomerAddressInput,
  ContentBlockTypes.DropdownInput,
  ContentBlockTypes.CheckboxInput,
  ContentBlockTypes.CheckboxGroupInput,
  ContentBlockTypes.EmailInput,
  ContentBlockTypes.ParentSelectionInput,
] as const;

export interface BlockConfig {
  type: ContentBlockTypes;
  key: string;
  polygonIntersectionMapping?: PolygonIntersectionMapping;
  defaultContent?: string;
}

export interface InputBlockConfig extends BlockConfig {
  label: string;
  attribute: string;
  required: boolean;
  defaultValue?: string | number | boolean | unknown[];
}

export interface BootstrapOption {
  text: string;
  value: string | number | boolean;
}

export interface SpeedTestBlockConfig extends BlockConfig {
  gaugeColors: string | string[];
  downloadAttributeName: string;
  uploadAttributeName: string;
}
// If provided an array of raw values, bootstrap components will transform these into BootstrapOptions at runtime.
export type BootstrapOptions = (BootstrapOption | string | boolean | number)[];

export interface RadioButtonBlockConfig extends InputBlockConfig {
  options: string[];
  defaultOption?: string;
}

export interface CheckboxGroupBlockConfig extends InputBlockConfig {
  options: BootstrapOptions;
}

export interface DropdownBlockConfig extends InputBlockConfig {
  options: string[];
  defaultOption?: string;
}

export interface CheckboxBlockConfig extends InputBlockConfig {
  uncheckedValue?: string | number | boolean;
  default?: boolean;
}

export interface CustomerAddressInputBlockConfig extends InputBlockConfig {
  addressAttributes?: Record<string, Record<string, string>>;
}

export interface MultiLineTextInputBlockConfig extends InputBlockConfig {
  characterLimit?: number;
}

export type ContentBlockConfig = BlockConfig | InputBlockConfig | SpeedTestBlockConfig;

export interface ContentMapping {
  value: string;
  content: string | ContentBlockConfig;
}
