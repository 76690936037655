import Vue from 'vue';
import Vuex from 'vuex';

// We disable the circular import check because the only thing we import in
//  a circular way here is the module State type, and Typescript types allow
//  circular dependencies, they will be resolved prior to runtime.
import type { ConfigState } from './config';
import type { ErrorState } from './error';
import type { SidebarState } from './sidebar';
import type { FeatureState } from './features';
import type { LayerState } from './layers';
import type { SearchState } from './search';
import type { SpinnersState } from './spinners';
import type { MapState } from './map';
import type { LegendState } from './legend';
import type { CommentState } from './comments';
import config from './config';
import error from './error';
import sidebar from './sidebar';
import features from './features';
import layers from './layers';
import search from './search';
import spinners from './spinners';
import polygonIntersections from './polygonIntersections';
import map from './map';
import legend from './legend';
import comments from './comments';

Vue.use(Vuex);

export interface RootState {
  config: ConfigState;
  error: ErrorState;
  sidebar: SidebarState;
  features: FeatureState;
  layers: LayerState;
  search: SearchState;
  spinners: SpinnersState;
  map: MapState;
  legend: LegendState;
  comments: CommentState;
}

export const storeConfig = {
  strict: process.env.NODE_ENV === 'development',
  modules: {
    config,
    error,
    sidebar,
    features,
    layers,
    search,
    spinners,
    map,
    polygonIntersections,
    legend,
    comments,
  },
};

export default new Vuex.Store(storeConfig);
