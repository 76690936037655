import { MutationTree, ActionTree, GetterTree } from 'vuex';
import axios from 'axios';
import _groupBy from 'lodash.groupby';
import _mapValues from 'lodash.mapvalues';
import _cloneDeep from 'lodash.clonedeep';
import _uniq from 'lodash.uniq';
import type { RootState } from '@/store';
import { MutationTypes, COMMENT_SPINNER } from '@/constants';
import { Polygon } from '@turf/helpers';
import {
  VetroResponse,
  isFailedResponse,
  SubmissionConfig,
  ParentChildPair,
  Feature,
} from '@/types';
import { ClusterInfo, emtpyClusterInfo } from '@/util/clustering';
import { fetchFeatures } from '@/util/features';

export class CommentState {
  comments: Feature[] = [];
  parentsById: Record<string, Feature> = {};
  showCommentsOnMap = false;
  hoveredClusterInfo: ClusterInfo = _cloneDeep(emtpyClusterInfo);
}

export const mutations: MutationTree<CommentState> = {
  [MutationTypes.SET_COMMENTS](state: CommentState, comments: Feature[]) {
    state.comments = comments;
  },
  [MutationTypes.SET_PARENTS](state: CommentState, parentsById: Record<string, Feature>) {
    state.parentsById = parentsById;
  },
  [MutationTypes.SET_SHOW_COMMENTS_IN_MAP](state: CommentState, showCommentsOnMap: boolean) {
    state.showCommentsOnMap = showCommentsOnMap;
  },
  [MutationTypes.SET_HOVERED_CLUSTER_INFO](state: CommentState, hoveredClusterInfo: ClusterInfo) {
    state.hoveredClusterInfo = hoveredClusterInfo ?? _cloneDeep(emtpyClusterInfo);
  },
};

export const actions: ActionTree<CommentState, RootState> = {
  setHoveredClusterInfo({ commit }, hoveredClusterInfo: ClusterInfo): void {
    commit(MutationTypes.SET_HOVERED_CLUSTER_INFO, hoveredClusterInfo);
  },
  setShowCommentsOnMap({ commit }, showCommentsOnMap: boolean): void {
    commit(MutationTypes.SET_SHOW_COMMENTS_IN_MAP, showCommentsOnMap);
    if (!showCommentsOnMap) {
      commit(MutationTypes.SET_HOVERED_CLUSTER_INFO, null);
    }
  },
  async fetchCommentsInPolygon({ commit, dispatch, rootGetters }, polygon: Polygon) {
    dispatch('spinners/activateSpinner', COMMENT_SPINNER, {
      root: true,
    });

    const submissionConfig = rootGetters['config/getSubmissionConfig'] as
      | SubmissionConfig
      | undefined;

    const { data: response }: { data: VetroResponse<ParentChildPair[]> } = await axios.post(
      'v2/features/intersection',
      {
        geometry: polygon,
        layer_ids: [submissionConfig?.childLayerId],
        plan_ids: [submissionConfig?.submissionPlanId],
      },
    );

    if (isFailedResponse(response)) {
      const error = new Error('Failed to fetch comments.');
      dispatch('error/setCriticalError', error, { root: true });
      throw error;
    }

    commit(MutationTypes.SET_COMMENTS, response.result);
    await dispatch('fetchCommentParents');

    dispatch('spinners/deactivateSpinner', COMMENT_SPINNER, {
      root: true,
    });
  },

  async fetchCommentParents({ commit, state }) {
    const { comments, parentsById } = state;
    const newParentVetroIds = _uniq(comments.map((comment) => comment.xVetro.parentVetroId)).filter(
      (id) => id && !parentsById[id],
    ) as string[];

    const { result: parents } = await fetchFeatures(newParentVetroIds);

    const newParentsById = Object.fromEntries(
      parents.map((parent) => [parent.xVetro.vetroId, parent]),
    );

    commit(MutationTypes.SET_PARENTS, { ...parentsById, ...newParentsById });
  },
};

export const getters: GetterTree<CommentState, RootState> = {
  commentParentPairs(state): ParentChildPair[] {
    return state.comments.map((comment: Feature) => ({
      parent: comment.xVetro.parentVetroId ? state.parentsById[comment.xVetro.parentVetroId] : null,
      child: comment,
    }));
  },
  commentsByParentId: (__state, localGetters) => {
    const pairsGroupedByParent = _groupBy(
      localGetters.commentParentPairs.map((pair: ParentChildPair) => ({
        parentId: pair.parent?.xVetro?.vetroId ?? -1,
        comment: pair.child,
      })),
      (x) => x.parentId,
    );

    return _mapValues(pairsGroupedByParent, (pairs) => pairs.map((pair) => pair.comment));
  },
  hoveredCommentIds: (state) => {
    return new Set(state.hoveredClusterInfo.features.map((f) => f.properties?.vetroId));
  },
};

export default {
  namespaced: true,
  state: (): CommentState => new CommentState(),
  mutations,
  actions,
  getters,
};
